import Vue from "vue"
import Widget from './brand-widget'
import Loading from '@/components/widgets/Loading.vue'
import SortedTablePlugin from "vue-sorted-table"
import Paginate from "@/components/widgets/pagination.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})

export default {
  components: {
    Loading,
    Widget,
    Paginate
  },
  data: function() {
    return {
      role: "",
      branch: "",
      loading: false,
      busy: false,
      sortBy: '',
      currentPage: 1, 
      currentData: {},
      perPage: 8,
      sortDesc: true,
      keyword: "",
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      create: {
        Id: null,
        mode: 'create',
        state: false
      },
      info: {
        Id: null,
        state: false
      },
      fields: [
        { 
          key: 'name', 
          label: 'ชื่อยี่ห้อ', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2',
          sortable: true 
        },
        { 
          key: 'description', 
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 text-xs-1/2',
          label: 'รายละเอียด' 
        },
        { 
          key: 'action', 
          label: '#', 
          thClass: 'font-weight-bold text-xs-1/2',
          class: 'text-gray-400 text-center text-xs-1/2' 
        }
      ],
      items: []
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.onInitData(1)
      await this.getRole()
    })
  },
  methods: { 
    ondeleted(id) {
      this.$swal({
        width: "29rem",
        padding: '1.825em',
        html: '<div class="d-flex flex-row align-items-center">' +
          '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
          '<div class="d-flex flex-column">'+
          '<span class="font-bold text-base py-2.5 text-left">คุณต้องการจะลบข้อมูลนี้หรือไม่?</span>'+
          '<span class="text-base text-left text-gray-500">หากคุณยืนยันแล้ว ข้อมูลจะไม่สามารถกู้คืนกลับมาได้?</span>'+
          '</div>'+
        '</div>',
          allowOutsideClick: false,
          focusConfirm: false,
          customClass: {
            popup: 'swal2-custom-rounded',
            closeButton: 'text-3xl swal2-custom-close',
            actions: 'justify-content-end',
            contant: 'd-flex flex-row justify-content-around px-2',
            title: 'd-none',
            confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
            cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
          },
          cancelButtonColor: '#dc4a38',
          cancelButtonText: 'ยกเลิก',
          confirmButtonText: "ตกลง",
          showCancelButton: true,
          showCloseButton: true,
      }).then(async confirm => {
        if(confirm.value){
          this.loading = true
          this.onConfirmDelete(id)
        }
      })
    },
    oncreate() {
      this.create.Id = null
      this.create.mode = 'create'
      this.create.state = !this.create.state
    },
    handleCreateEvent(event) {
      if (event.status) {
        this.create.state = false
        setTimeout(() => {
          const currentPage = this.currentPage
          this.onInitData(currentPage)
        }, 500)
      }
    },
    onupdate(id) {
      this.create.Id = id
      this.create.mode = 'update'
      this.create.state = !this.create.state
    },
    async onConfirmDelete(id) {
      try {
        const result = await this.$axios.delete(this.$host + "/api/v1/brand/" + id)
        if (result.status === 204) {
          this.loading = false
  
          this.$swal({
          width: "29rem",
          padding: '1.825em',
          html: '<div class="d-flex flex-row align-items-center">' +
            '<i class="fa fa-2x mr-3 fa-exclamation-triangle text-info"></i>'+
            '<div class="d-flex flex-column">'+
            '<span class="font-bold text-base py-2.5 text-left">แจ้งเตือน</span>'+
            '<span class="text-base text-left text-gray-500">ลบข้อมูลเรียบร้อยแล้ว</span>'+
            '</div>'+
          '</div>',
            allowOutsideClick: false,
            focusConfirm: false,
            customClass: {
              popup: 'swal2-custom-rounded',
              closeButton: 'text-3xl swal2-custom-close',
              actions: 'justify-content-end',
              contant: 'd-flex flex-row justify-content-around px-2',
              title: 'd-none',
              confirmButton: "btn btn-info py-3 rounded-lg text-white font-prompt-light text-sm font-normal",
              cancelButton: 'btn btn-google py-3 rounded-lg text-white font-prompt-light text-sm font-normal'
            },
            cancelButtonColor: '#dc4a38',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: "ตกลง",
            showCancelButton: false,
            showCloseButton: true,
          }).then(async confirm => {
            if(confirm.value){
              this.onInitData(this.currentPage)
            }
          })
        }
      } catch (error) {
        this.loading = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async onInitData(currentPage) {
      this.currentPage = currentPage;
      this.busy = true;
      this.apiStatus = "กำลังค้นหาข้อมูล..";
      this.items = [];
      try {
        const page = currentPage ? currentPage : 1
        const result = await this.$axios.get(
          `${Vue.prototype.$host}/api/v1/brands?page=${page}&perPage=8&keyword=${this.keyword}`
        )
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        if (result) {
          this.currentData = result.data;
          this.items = result.data.data
          this.totalItem = result.data.total          
        } else {
          this.items = []
          this.totalItem = 0  
        }
      } catch (error) {
        this.busy = false
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
  }
}